import balasNoszczyk from 'assets/Balas-Noszczyk.png'
import kostrzewa from 'assets/Kostrzewa.png'
import krawczyk from 'assets/Krawczyk.png'

export const temporaryBoard = [
  {
    name: 'Beata Balas-Noszczyk',
    role: '(Członkini rady)',
    image: balasNoszczyk,
    shortBio: 'Senior Partner w kancelarii DWF, wcześniej Partner Zarządzający kancelarii Hogan Lovells w Polsce (2000–2024).',
    bio: 'Senior Partner w kancelarii DWF, wcześniej Partner Zarządzający kancelarii Hogan Lovells w Polsce (2000–2024). Jeden z najbardziej uznanych specjalistów w zakresie prawa bankowo-finansowego oraz ubezpieczeniowego. Uhonorowana tytułem „Prawnika 30-lecia” przyznawanego przez Polski Związek Pracodawców Prawniczych i Rzeczpospolitą. Odznaczona Krzyżem Kawalerskim Orderu Odrodzenia Polski za zasługi na rzecz rozwoju rynku ubezpieczeniowego w Polsce.'
  },
  {
    name: 'Wojciech Kostrzewa',
    role: '(Członek rady)',
    image: kostrzewa,
    shortBio: 'Przedsiębiorca i menedżer z doświadczeniem w sektorze finansowym, medialnym i technologicznym.',
    bio: 'Przedsiębiorca i menedżer z doświadczeniem w sektorze finansowym, medialnym i technologicznym. Przewodniczący RN Alior Bank SA, członek rady dyrektorów Stadler Rail AG, wiceprzewodniczący RN ERGO Hestia SA, członek RN Gremi Media SA, członek advisory board Squirro AG. Właściciel Wydawnictwa Pascal, udziałowiec Billon Group Ltd. Prezes PRB. Absolwent wydziału ekonomii Uniwersytetu w Kilonii (Niemcy).'
  },
  {
    name: 'Krzysztof Krawczyk',
    role: '(Członek rady)',
    image: krawczyk,
    shortBio: 'Dyrektor warszawskiego biura i partner CVC Capital Partners, wiodącej firmie z zakresu private equity i doradztwa inwestycyjnego na świecie.',
    bio: 'Dyrektor warszawskiego biura i partner CVC Capital Partners, wiodącej firmie z zakresu private equity i doradztwa inwestycyjnego na świecie. Należał do zarządów wielu spółek portfelowych CVC, m.in. Żabka, Stock Spirits Group, PKP Energetyka, D-Marin oraz AR Packaging. Wcześniej partner zarządzający Innova Capital. Członek zarządu Fundacji TVN oraz Valores, pierwszego funduszu filantropijnego w regionie CEE.'
  },
]
